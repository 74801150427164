<template>
  <Page title="ReleaseNotes" color="grey">
    <template slot="extension">
      <v-tabs background-color="grey darken-1">
        <v-toolbar color="transparent" flat dense>
          <v-spacer />
          <v-text-field
            single-line
            dense
            hide-details
            label="Suche"
            v-model="search"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </v-toolbar>
      </v-tabs>
    </template>

    <v-alert
      v-if="!search"
      type="info"
      text
      border="left"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Die Release Notes umfassen {{ filteredItems.length }} Einträge.
    </v-alert>
    <v-alert
      v-if="search && filteredItems.length == 0"
      type="error"
      text
      :prominent="!$vuetify.breakpoint.xs"
    >
      Die Suche nach «{{ search }}» ergab keinen Treffer!
    </v-alert>
    <v-alert
      v-if="search && filteredItems.length > 0"
      type="success"
      text
      :prominent="!$vuetify.breakpoint.xs"
    >
      {{ filteredItems.length }} Suchergebnis{{
        filteredItems.length > 1 ? "se" : ""
      }}
      für «{{ search }}».
    </v-alert>

    <v-timeline :dense="$vuetify.breakpoint.xs">
      <v-timeline-item
        v-for="(item, index) in filteredItems"
        :key="index"
        :color="item.color"
        dark
        large
        fill-dot
      >
        <template v-slot:icon>
          <v-icon>
            {{ item.icon ? item.icon : "mdi-alert" }}
          </v-icon>
        </template>
        <template v-slot:opposite>
          <span :class="`headline font-weight-bold ${item.color}--text`"
            ><DateValue :value="item.date"
          /></span>
        </template>
        <div
          :class="`py-4 ${
            !$vuetify.breakpoint.xs && index % 2 ? 'text-right' : ''
          }`"
        >
          <h2 :class="`headline font-weight-light`">
            <span
              v-if="$vuetify.breakpoint.xs"
              :class="`headline font-weight-bold`"
              ><DateValue :value="item.date"
            /></span>
            {{ item.title }}
          </h2>
          <div v-html="item.text"></div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import items from "./releaseNotes";

export default defineComponent({
  components: { DateValue },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredItems() {
      return items.filter(
        (item) =>
          item.title.toLowerCase().includes(this.search.toLowerCase()) ||
          item.text.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {},
});
</script>
